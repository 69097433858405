import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import {
  setHuddleDay,
  setHuddleId,
  setHuddleTime,
  startOrJoinHuddle,
  setTopicArticle,
  fetchVegxHuddleArticleId,
} from "../../reducers/huddleSlice";
import {
  fetchUserHuddles,
  fetchUserHuddlesByDateRange,
} from "../../reducers/userHuddlesSlice";
import { getCurrentDateTime } from "@/app/_utils/helper";
import { Button } from "../buttons/button";
import { Input } from "../forms/input";
import LocationSearchInput from "../search/LocationSearchInput";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/app/_components/ui/dialog";
import HuddleTopicPicker from "./HuddleTopicPicker";
import { AppDispatch } from "../../reducers/store";
import RocketIcon from "../graphics/RocketIcon";
import { setSelectedLocation } from "../../reducers/locationDashboardSlice";
import { getFormattedDate } from "@/app/_utils/formatter";
import { getMonthStart, getMonthEnd } from "@/app/_utils/helper";

const AddHuddleDialog = ({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
}) => {
  console.log("AddHuddleDialog");
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [articleMode, setArticleMode] = useState<"daily" | "pick" | "vegx">(
    "daily",
  );
  const [hasHuddledOnVegx, setHasHuddledOnVegx] = useState(false);
  const [changeDetails, setChangeDetails] = useState(false);

  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const dailyArticleId = useSelector(
    (state: RootState) => state.dailyArticle.article.id,
  );
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );
  const articles = useSelector((state: RootState) => state.articles);
  const dailyArticle = articles.articles[dailyArticleId];
  const topicArticle = useSelector(
    (state: RootState) => state.huddleGroups.topicArticle,
  );
  const vegxHuddleArticleId = useSelector(
    (state: RootState) => state.huddleGroups.vegxHuddleArticleId,
  );
  const vegxHuddleArticle = vegxHuddleArticleId
    ? articles.articles[vegxHuddleArticleId]
    : undefined;

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (open) {
      const { currentDateString, currentTimeString } = getCurrentDateTime();
      if (selectedLocation?.name.startsWith("VQ -")) {
        dispatch(setSelectedLocation({ ...selectedLocation, name: "VQ" }));
      }
      setSelectedDate(currentDateString);
      setSelectedTime(currentTimeString);

      if (accessToken) {
        const monthStart = getMonthStart();
        const monthEnd = getMonthEnd();

        // Fetch VEGX Huddle Article
        const month = new Date().toLocaleString("en-US", { month: "long" });
        dispatch(fetchVegxHuddleArticleId({ month, accessToken }));

        // Fetch huddles for the current month
        dispatch(
          fetchUserHuddlesByDateRange({
            accessToken,
            query: { from_date: monthStart, to_date: monthEnd },
          }),
        ).then((response: any) => {
          const huddles = response.payload;
          const hasVegx = Object.values(huddles).some(
            (huddle: any) => huddle.articleId === vegxHuddleArticleId,
          );
          setHasHuddledOnVegx(hasVegx);
        });
      }
    }
  }, [open, selectedLocation, accessToken, vegxHuddleArticleId, dispatch]);

  useEffect(() => {
    // if (vegxHuddleArticle && !hasHuddledOnVegx) {
    //   setArticleMode("vegx");
    //   dispatch(setTopicArticle(vegxHuddleArticle));
    // } else if (!vegxHuddleArticle || hasHuddledOnVegx) {
    //   setArticleMode("daily");
    //   if (dailyArticle) {
    //     dispatch(setTopicArticle(dailyArticle));
    //   }
    // }
    setArticleMode("daily");
    if (dailyArticle) {
      dispatch(setTopicArticle(dailyArticle));
    }
  }, [vegxHuddleArticle, dailyArticle, hasHuddledOnVegx, dispatch]);

  if (!accessToken) return null;

  const onSaveChanges = async () => {
    if (selectedLocation) {
      setError(null);
      try {
        const dateTime = new Date(`${selectedDate}T${selectedTime}`);
        const timeDisplay = `${dateTime.getHours() % 12 || 12}:${
          dateTime.getMinutes() < 10 ? "0" : ""
        }${dateTime.getMinutes()}${dateTime.getHours() >= 12 ? "pm" : "am"}`;

        const huddleTime = `${selectedDate}T${selectedTime}`;
        const huddleDate = new Date(huddleTime);
        console.log("onSaveChanges", huddleTime);

        const formattedHuddleDay = `${dateTime.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })} @ ${timeDisplay}`;

        dispatch(setHuddleDay(formattedHuddleDay));

        const body = {
          huddleTime: huddleDate.toISOString().slice(0, -1),
          location: selectedLocation.name,
        };

        dispatch(setHuddleId(null));
        dispatch(setHuddleTime(huddleTime));

        dispatch(
          startOrJoinHuddle({
            articleId: topicArticle.id,
            body,
            accessToken,
            type: "start",
          }),
        ).then(async () => {
          setOpen(false);
          await dispatch(fetchUserHuddles(accessToken));
        });
      } catch (err: unknown) {
        setError(err instanceof Error ? err.message : "An error occurred");
      }
    } else {
      setError("Please select a location");
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button id="startHuddle" className="w-full px-4" variant="secondary">
            <PlusCircleOutlined className="mr-1" /> Start a Huddle
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <div
          id="addHuddleDialog"
          className="flex flex-col gap-4 items-center justify-center p-10 max-w-[540px] text-center"
        >
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full bg-red-secondary">
            <RocketIcon className="relative sm:scale-[.875] top-px right-px" />
          </div>
          <div className="text-lg -mt-4">New Huddle</div>

          {error && (
            <div className="w-full py-2 text-red-600 italic text-center">
              {error}
            </div>
          )}

          {changeDetails || !selectedLocation ? (
            <>
              <div className="w-full text-left">
                <label className="block text-neutral-700 text-sm mb-1">
                  <span className="text-red-600">*</span> Location:
                </label>
                <LocationSearchInput
                  additionalFilter={(item) => !item.name.includes("VQ -")}
                />
              </div>
              <div className="w-full text-left">
                <label className="block text-neutral-700 text-sm mb-1">
                  Time of Huddle:
                </label>
                <div className="grid grid-cols-2 gap-[7px]">
                  <Input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="block"
                  />
                  <Input
                    type="time"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    className="block"
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="text-xl font-semibold">
                {selectedLocation.name}
              </div>
              <div>{getFormattedDate(new Date())}</div>
              <button
                onClick={() => setChangeDetails(true)}
                className="underline text-sm text-blue-tint"
              >
                Change
              </button>
            </div>
          )}

          <HuddleTopicPicker
            {...{
              articleMode,
              setArticleMode,
              dailyArticle,
              topicArticle,
              vegxHuddleArticleId,
              vegxHuddleArticleTitle: vegxHuddleArticle?.title,
              setTopicArticle,
              hasHuddledOnVegx,
              dispatch,
            }}
          />
          <div className="flex w-full justify-end mt-4 space-x-4">
            <Button onClick={() => setOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button id="saveHuddle" variant="primary" onClick={onSaveChanges}>
              Save Huddle
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddHuddleDialog;
